import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import 'moment/locale/fr'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import Loader from '../components/Loader'
import { findCompany } from '../api'
import Button from '../components/Button'
import { Bar, VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLegend, VictoryTheme, VictoryTooltip } from 'victory'
moment.locale('fr')

const match = (typeof window !== `undefined`) ? (window.location.pathname.match(/graphs\/(\d+)_(.+)/) || []) : []
const companyId = match[1]
const token = match[2]

function sameMonth(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth()
}

function sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
}

function transformCurrentYear(d) {
  const clone = new Date(d)
  clone.setYear(new Date().getFullYear())
  return clone
}

function defaultValue(d) {
  return [{ x: new Date('2023-01-01'), y: 0 }, ...d]
}

function substituteBooth(p) {
  const swap = { 6: 24, 9: 25 }
  return { ...p, booth: swap[p.booth] || p.booth }
}

function setDateToBegginingOfMonth(p) {
  const date = new Date(p.x)
  date.setDate(1) 
  return { ...p, x: date }
}

function year2023(p) {
  return p.x > new Date('2023-01-01') && p.x < new Date('2024-01-01')
}

function thisYear(p) {
  return p.x > new Date('2024-01-01')
}

function lastYear(p) {
  return new Date('2022-01-01') && p.x < new Date('2023-01-01')
}

export default function DashboardPage() {
  const [company, setCompany] = useState()
  useEffect(() => {
    findCompany(companyId, token).then(c =>
      setCompany({
        ...c,
        payments: c.payments.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).map(substituteBooth),
        booths: c.booths.filter(b => ![1, 6, 9].includes(b.id)).sort((a, b) => c.configs.find(c => c.id === a.config).name.localeCompare(c.configs.find(c => c.id === b.config).name)) 
      })
    )
  }, [])
  console.log(company)

  const compiled = useMemo(() => {
    if (!company) return
    const mapped = company.payments.filter(p => p.booth !== 1).map(p => {
      const date = new Date(p.created_at)
      // date.setDate(1) 
      return { x: date, prints: p.prints, sessions: 1, booth: p.booth }
    })
    const groupByBooth = mapped.reduce((acc, cur) => {
      acc[cur.booth] = [...acc[cur.booth], cur]
      return acc
    }, company.booths.reduce((acc, curr) => ({ ...acc, [curr.id]: [] }), {}) )  
    const gatherSameSlot = (ps, agregator) => ps.reduce((acc, cur) => {
      const match = acc.find(({ x }) => agregator(x, cur.x)) || {}
      return [
        ...acc.filter(p => !agregator(p.x, cur.x)),
        {
          x: cur.x,
          prints: (match.prints || 0) + cur.prints,
          sessions: (match.sessions || 0) + cur.sessions,
          booths: [...new Set([...(match.booths || []), cur.booth])]
        }
      ]
    }, [])
    return {
      boothsByMonth: company.booths.map(booth => gatherSameSlot(groupByBooth[booth.id], sameMonth).map(setDateToBegginingOfMonth)),
      boothsByDays: company.booths.map(booth => gatherSameSlot(groupByBooth[booth.id], sameDay)),
      avgByMonth: gatherSameSlot(mapped, sameMonth).map(setDateToBegginingOfMonth),
      avgByDays: gatherSameSlot(mapped, sameDay)
    }
  }, [company])
  console.log(compiled)

  if (!company) return <Loader />

  const payments = company.payments

  const downloadCSV = () => {
    let csv = `data:text/csv;charset=utf-8,${payments.filter(p => p.booth !== 1).map(p => [p.created_at, `${company.configs.find(c => c.id === company.booths.find(b => b.id === p.booth).config).name.replace('LIS', '')} - ${company.booths.find(b => b.id === p.booth).name}`, p.prints]).join('\n')}`
    const link = document.createElement('a')
    link.setAttribute('href', encodeURI(csv))
    link.setAttribute('download', 'export_emails_lis.csv')
    document.body.appendChild(link)
    link.click()
  }


  const avgSessionsPerDay = (ps, filter) => {
    const data = ps.filter(filter)
    return (data.reduce((acc, cur) => acc + (cur.sessions / cur.booths.length), 0) / data.length).toFixed(1)
  }

  const avgPrintsPerDay = (ps, filter) => {
    const data = ps.filter(filter)
    return (data.reduce((acc, cur) => acc + (cur.prints / cur.booths.length), 0) / data.length).toFixed(1)
  }

  // console.log(avgSessionsPerDay(), avgPrintsPerDay())
  
  return (
    <div className="text-black mx-auto w-screen max-w-screen-xl px-4 lg:px-0">
      <div className="text-2xl lg:text-3xl font-medium text-center mt-4">Suivi des ventes Wizito</div>
      <img src={company.logo.url} className="block h-24 lg:h-32 mx-auto" />
      <Button variant="secondary" onClick={downloadCSV} className="bg-blue-700 bg-opacity-100 mx-auto mb-4 mt-2 font-bold">Télécharger les {payments.length} sessions</Button>
      {company.booths.map((booth, index) =>
        <div className="">
          <VictoryChart
            padding={50}
            domainPadding={16}
            height={250}
            width={600}
            theme={VictoryTheme.material}
            scale={{ x: "time" }}
            domain={{x: [new Date('2024-01-01'), new Date('2024-12-02')], y: [0, 400]}}>
            <VictoryLegend
              orientation="horizontal"
              title={`${company.configs.find(c => c.id === booth.config).name.replace('LIS', '')} - ${booth.name}`}
              borderPadding={0}
              style={{ labels: { fontSize: 9 } }}
              colorScale={["#ffc6a0", "#c3723d", "#2460db", "#e1e1e1"]}
              data={[{ name: '2022' }, { name: '2023' }, { name: '2024' }, { name: 'Moyenne 2023' }]} />
            <VictoryAxis
              dependentAxis
              scale={{ y: 'linear' }}
              tickCount={5}
              style={{ tickLabels: { fontSize: '8px' } }} />
            <VictoryAxis
              scale={{ x: 'time' }}
              tickCount={12}
              style={{ tickLabels: { fontSize: '8px' } }}
              tickFormat={t => `${new Intl.DateTimeFormat('fr', { month: 'long' }).format(t)}`} />
            <VictoryGroup offset={6} colorScale={["#ffc6a0", "#c3723d", "#2460db", "#e1e1e1"]}>
              <VictoryBar
                labels={({ datum }) => `2022 : ${datum.y} sessions`}
                labelComponent={<VictoryTooltip dy={0} cornerRadius={2} flyoutStyle={{ fill: '#19194b' }} style={{ fontSize: '9px', fill: '#FFF' }} />}
                barWidth={5}
                data={defaultValue(compiled.boothsByMonth[index].filter(lastYear).map(p => ({ x: transformCurrentYear(p.x), y: p.sessions })))} />
              <VictoryBar
                labels={({ datum }) => `2023 : ${datum.y} sessions`}
                labelComponent={<VictoryTooltip dy={0} cornerRadius={2} flyoutStyle={{ fill: '#19194b' }} style={{ fontSize: '9px', fill: '#FFF' }} />}
                barWidth={5}
                data={defaultValue(compiled.boothsByMonth[index].filter(year2023).map(p => ({ x: transformCurrentYear(p.x), y: p.sessions })))} />
              <VictoryBar
                labels={({ datum }) => `2024 : ${datum.y} sessions`}
                labelComponent={<VictoryTooltip dy={0} cornerRadius={2} flyoutStyle={{ fill: '#19194b' }} style={{ fontSize: '9px', fill: '#FFF' }} />}
                barWidth={5}
                data={defaultValue(compiled.boothsByMonth[index].filter(thisYear).map(p => ({ x: p.x, y: p.sessions })))} />
              <VictoryBar
                labels={({ datum }) => `Moyenne 2023 : ${datum.y} sessions`}
                labelComponent={<VictoryTooltip dy={0} cornerRadius={2} flyoutStyle={{ fill: '#19194b' }} style={{ fontSize: '9px', fill: '#FFF' }} />}
                barWidth={5}
                data={compiled.avgByMonth.filter(thisYear).map(p => ({ x: p.x, y: Math.round(p.sessions / p.booths.length) }))} />
            </VictoryGroup>
          </VictoryChart>
          <div className="flex w-full justify-center -mt-10 mb-10">
            <div className="flex flex-col justify-center items-center mx-4 font-bold">
              sessions / jour
              <div className="flex">
                <div className="text-3xl w-16 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#ffc6a0' }}>
                  {avgSessionsPerDay(compiled.boothsByDays[index], lastYear)}
                  <div className="text-sm w-full absolute bottom-0 -mb-5 text-black">{((avgSessionsPerDay(compiled.boothsByDays[index], lastYear) / avgSessionsPerDay(compiled.boothsByDays[index], year2023) * 100) - 100).toFixed(1)}%</div>
                </div>
                <div className="text-3xl w-16 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#c3723d' }}>
                  {avgSessionsPerDay(compiled.boothsByDays[index], year2023)}
                </div>
                <div className="text-3xl w-16 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#2460db' }}>
                  {avgSessionsPerDay(compiled.boothsByDays[index], thisYear)}
                </div>
                <div className="text-3xl w-16 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#e1e1e1' }}>
                  {avgSessionsPerDay(compiled.avgByDays, year2023)}
                  <div className="text-sm w-full absolute bottom-0 -mb-5 text-black">{((avgSessionsPerDay(compiled.avgByDays, year2023) / avgSessionsPerDay(compiled.boothsByDays[index], year2023) * 100) - 100).toFixed(1)}%</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center mx-4 font-bold">
              prints / jour
              <div className="flex">
                <div className="text-3xl w-20 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#ffc6a0' }}>
                  {avgPrintsPerDay(compiled.boothsByDays[index], lastYear)}
                  <div className="text-sm w-full absolute bottom-0 -mb-5 text-black">{((avgPrintsPerDay(compiled.boothsByDays[index], lastYear) / avgPrintsPerDay(compiled.boothsByDays[index], year2023) * 100) - 100).toFixed(1)}%</div>
                </div>
                <div className="text-3xl w-20 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#c3723d' }}>
                  {avgPrintsPerDay(compiled.boothsByDays[index], year2023)}
                </div>
                <div className="text-3xl w-20 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#2460db' }}>
                  {avgPrintsPerDay(compiled.boothsByDays[index], thisYear)}
                </div>
                <div className="text-3xl w-20 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#e1e1e1' }}>
                  {avgPrintsPerDay(compiled.avgByDays, year2023)}
                  <div className="text-sm w-full absolute bottom-0 -mb-5 text-black">{((avgPrintsPerDay(compiled.avgByDays, thisYear) / avgPrintsPerDay(compiled.boothsByDays[index], thisYear) * 100) - 100).toFixed(1)}%</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center mx-4 font-bold">
              prints / session
              <div className="flex">
                <div className="text-3xl w-20 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#ffc6a0' }}>
                  {(avgPrintsPerDay(compiled.boothsByDays[index], lastYear) / avgSessionsPerDay(compiled.boothsByDays[index], lastYear)).toFixed(1)}
                  <div className="text-sm w-full absolute bottom-0 -mb-5 text-black">{(((avgPrintsPerDay(compiled.boothsByDays[index], lastYear) / avgSessionsPerDay(compiled.boothsByDays[index], lastYear)) / (avgPrintsPerDay(compiled.boothsByDays[index], year2023) / avgSessionsPerDay(compiled.boothsByDays[index], thisYear)) * 100) - 100).toFixed(1)}%</div>
                </div>
                <div className="text-3xl w-20 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#c3723d' }}>
                  {(avgPrintsPerDay(compiled.boothsByDays[index], year2023) / avgSessionsPerDay(compiled.boothsByDays[index], year2023)).toFixed(1)}
                </div>
                <div className="text-3xl w-20 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#2460db' }}>
                  {(avgPrintsPerDay(compiled.boothsByDays[index], thisYear) / avgSessionsPerDay(compiled.boothsByDays[index], thisYear)).toFixed(1)}
                </div>
                <div className="text-3xl w-20 text-center text-white rounded mx-1 relative" style={{ backgroundColor: '#e1e1e1' }}>
                  {(avgPrintsPerDay(compiled.avgByDays, year2023) / avgSessionsPerDay(compiled.avgByDays, year2023)).toFixed(1)}
                  <div className="text-sm w-full absolute bottom-0 -mb-5 text-black">{(((avgPrintsPerDay(compiled.avgByDays, year2023) / avgSessionsPerDay(compiled.avgByDays, year2023)) / (avgPrintsPerDay(compiled.boothsByDays[index], year2023) / avgSessionsPerDay(compiled.boothsByDays[index], year2023)) * 100) - 100).toFixed(1)}%</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      )}

    </div>
  )
}

